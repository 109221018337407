import React, {useEffect, useState} from 'react'
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import {
  triggerSuccess,
  triggerError,
  setErrorMessage,
  setSuccessMessage,
} from '../Request/StatusFormatter';

const EditInvestment = () => {    
const initialinvestment = {
            amount: '',
            date: '',
            status: 0,
        }
const [investmentForm, setinvestmentForm] = useState(initialinvestment);
const [currentinvestment, setCurrentinvestment] = useState('');
const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);

const onChange = (e) =>
setinvestmentForm({ ...investmentForm, [e.target.name]: e.target.value });

const { isLoading: isSendingRequest, mutate: postinvestment } = useMutation(
    async () => {
      return await apiClient.post(`/api/update-investment/${currentinvestment}`, {
        amount: investmentForm.amount,
        date: investmentForm.date,
        status: investmentForm.status,
      });
    },
    {
      onSuccess: (res) => {
        setSuccessMessage(res.data);
        triggerSuccess(); 
        
      },
      onError: (err) => {
        setErrorMessage(err.response?.data || err);         
        triggerError();
      },
    }
  );

useEffect(() => {
    window.addEventListener('currentEditinvestmentSelected', function (e) {
        let investment = localStorage.getItem('currentEditinvestmentSelected');
        if (investment != null) {
            investment = JSON.parse(investment);
            setCurrentinvestment(investment.id);
            setinvestmentForm({...investmentForm,
                amount: investment.amount,
                status: investment.status,
            })
        }
    }, false);        
  })

  const {amount, status } = investmentForm;
  return (
    <div id="menu-admin-investments-edit" className="offcanvas offcanvas-bottom offcanvas-detached rounded-m">
            <div className="menu-size" style={{height: "540px"}}>
                <div className="d-flex mx-3 mt-3 py-1">
                    <div className="align-self-center">
                        <h1 className="mb-0">{getAmount(investmentForm.amount)}</h1>
                    </div>
                    <div className="align-self-center ms-auto">
                        <a href="/#" className= "ps-4 shadow-0 me-n2" data-bs-dismiss="offcanvas">
                            <i className="bi bi-x color-red-dark font-26 line-height-xl"></i>
                        </a>
                    </div>
                </div>
                <div className="divider divider-margins mt-3"></div>
                <form key={currentinvestment} className="content mt-0">
                <div className="pb-2"></div>
                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <input onChange={onChange} defaultValue={amount} type="text" name="amount" className="form-control rounded-xs bg-theme color-theme" id="name" placeholder="amount"/>
                            <label htmlFor="amount" className="color-highlight form-label-always-">amount</label>
                            <span>(amount)</span>
                        </div>
                        <div className="pb-2"></div>
                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-currency-dollar font-14"></i>
                            <input onChange={onChange} type="datetime-local" name="date" className="form-control rounded-xs bg-theme color-theme" id="date" placeholder="Date"/>
                            <label htmlFor="date" className="color-highlight form-label-always-">Date</label>
                            <span>(Date)</span>
                        </div>
                        <div className="pb-2"></div>
                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-currency-dollar font-14"></i>
                            <select onChange={onChange} defaultValue={status} type="number" name="status" className="form-control rounded-xs bg-theme color-theme" id="status" placeholder="status">
                                <option value="">select status</option>
                                <option value="pending">pending</option>
                                <option value="approved">approved</option>
                                <option value="completed">completed</option>
                                <option value="declined">declined</option>
                            </select>
                            <label htmlFor="status" className="color-highlight form-label-always-">status</label>
                            <span>(status)</span>
                        </div>
                        <div className="pb-2"></div>
                        <div onClick={postinvestment} className="btn btn-full gradient-green rounded-s shadow-bg shadow-bg-s mb-4">
                            {isSendingRequest? <span>updating...</span>
                            :
                            <span>Update investment</span>
                            }                            
                        </div>                     
                </form>                
            </div>
        </div>
  )
}

export default EditInvestment